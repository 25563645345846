// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
// .container data-controller="hello"
//   .section data-target="hello.output"

import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [
    "reorderLink"
  ];

  initialize() {}

  connect() {}

  reorder_drug_sales = async (_event) => {
    let checked = document.querySelectorAll('input[name="drug_sales"]:checked');
    if(checked.length === 0){
      alert("Please select a drug to reorder");
      _event.stopPropagation();
      return false;
    }
    let checked_arr = Array.from(checked);
    let checked_values = checked_arr.map((checkbox) => checkbox.value);
    let base_url = _event.target.dataset["baseUrl"];
    console.log(base_url);
    console.log(checked_values);
    _event.target.href = `${base_url}&reorder=${checked_values.join(",")}`
  }

  reorder_mouse_enter = (_event) => {
    let row = _event.target.closest(".row");
    row.querySelector(".reorder_link").classList.add("hide");
    row.querySelector(".reorder_checkbox").classList.remove("hide");
  }

  reorder_mouse_leave = (_event) => {
    let row = _event.target.closest(".row");
    let checkbox_container = row.querySelector(".reorder_checkbox");
    if(!checkbox_container.querySelector("input").checked) {
      checkbox_container.classList.add("hide");
      row.querySelector(".reorder_link").classList.remove("hide");
    }
  }
}
