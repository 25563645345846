/* eslint no-console:0 */

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// custom global imports (use sparingly prefer controllers instead)
import {
    check_max_length,
    resetSummaryPdfModal,
    resetNewRequestCstModal,
    showSubmitProgress,
    toggle_edit_case,
    resetNewCloseRequestModal,
    resetOrderRxHistoryModal,
    resetOrderRxStartModal,
    resetNewEnrollInTherapyRequestModal,
    resetEditInvoicingContactEmailRequestModal
} from './scripts/utils.js'
window.check_max_length = check_max_length
window.resetSummaryPdfModal = resetSummaryPdfModal
window.resetNewRequestCstModal = resetNewRequestCstModal
window.resetNewCloseRequestModal = resetNewCloseRequestModal
window.resetOrderRxHistoryModal = resetOrderRxHistoryModal
window.resetOrderRxStartModal = resetOrderRxStartModal
window.resetNewEnrollInTherapyRequestModal = resetNewEnrollInTherapyRequestModal
window.resetEditInvoicingContactEmailRequestModal = resetEditInvoicingContactEmailRequestModal
window.showSubmitProgress = showSubmitProgress
window.toggle_edit_case = toggle_edit_case


// styleguide
import { styleGuideSetup } from './styleguide/setup.js'
import { styleGuideTeardown } from './styleguide/teardown.js'
import { burgerToggle } from './styleguide/nav.js'
window.burgerToggle = burgerToggle

// turboloinks reload
//document.addEventListener("turbolinks:load", styleGuideSetup);
//document.addEventListener("turbolinks:before-cache", styleGuideTeardown );
document.addEventListener("turbolinks:load", styleGuideSetup, {once: true });
document.addEventListener('turbolinks:render', styleGuideSetup);
document.addEventListener('turbolinks:before-render', styleGuideTeardown );
window.addEventListener('load', styleGuideSetup);

